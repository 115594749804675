import React from 'react';

import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { fromScreen } from 'utils/media-query/responsive.util';
import { HeadlineIcon } from 'components/HeadlineIcon';
import { DolEventCardNewV2 } from 'components/DolNews/Card/DolEventCardNewV2';
import { graphql, useStaticQuery, Link } from 'gatsby';
import ButtonText from 'components/ButtonText';
import { SITE_URL } from '../../apis/baseURL';

const Main = styled.div`
  .section-title {
    margin-bottom: 24px;
    > div {
      display: grid;
      grid-template-columns: 1fr auto;
      justify-content: space-between;
    }
  }

  .headline-icon {
    display: block;
  }
  ${fromScreen(776)} {
    .section-title {
      margin-bottom: 40px;
    }
  }
`;

const EventList = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  row-gap: 24px;
  cursor: pointer;
  .divider-6,
  .divider-7 {
    display: block;
  }
  ${fromScreen(776)} {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    .divider-6,
    .divider-7 {
      display: none;
    }
  }
`;

const TextLinkWrap = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  margin-top: 70px;
  ${fromScreen(776)} {
    display: none;
  }
`;

const getPageUrl = url => {
  if (url) {
    return `${SITE_URL}/${url}`;
  }
  return null;
};

const AllDolEvents = ({ allLinearThinkingNews }) => {
  return (
    <Main>
      <HeadlineIcon
        iconHandle="x2dlqr50RdOvP6ZLm83K"
        url="/tin-tuc-ve-dol"
        className="section-title"
        isHomePage
      >
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          Sự kiện tiêu biểu của DOL
        </Typography>
      </HeadlineIcon>
      <EventList>
        {allLinearThinkingNews?.map((eachNews, idx) => (
          <DolEventCardNewV2
            key={idx}
            idx={idx}
            hrefUrl={getPageUrl(eachNews?.page?.url)}
            alt={eachNews?.cover?.alt}
            coverUrl={eachNews?.cover?.url}
            description={eachNews?.description}
            name={eachNews?.name}
          />
        ))}
      </EventList>
      <TextLinkWrap>
        <Link to="/tin-tuc-ve-dol">
          <ButtonText content="Xem tất cả" type="primary" />
        </Link>
      </TextLinkWrap>
    </Main>
  );
};

export default withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      query LinearThinkingNewsHomeQuery {
        allLinearThinkingNews {
          nodes {
            author
            blogId
            blogType
            description
            id
            listingStatus
            name
            numOfViews
            status
            tableOfContent
            cover {
              path
              url
              size
              caption
              alt
            }
            page {
              url
            }
            title
            tocTitle
          }
        }
      }
    `);
    const { allLinearThinkingNews } = data || {};
    return (
      <AllDolEvents allLinearThinkingNews={allLinearThinkingNews?.nodes} />
    );
  },
  {
    style: {
      background: colorsV2.white100
    }
  },
  {}
);
