import React, { useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';
import { HeadlineIcon } from 'components/HeadlineIcon';
import Button from 'components/Button';

import ListItem from './ListItem';
import { useDeviceDetect3 } from 'utils/hooks/useDeviceQuery.hook';
import { withHomePageSection } from 'components/withHomePageSection';
import { fromScreen } from 'utils/media-query/responsive.util';

const fetchHallOfFame = async ({ page, limit }) => {
  const res = await axios.get(
    `${process.env.NODE_ENDPOINT}/hallOfFameList?_page=${page}&_limit=${limit}&_sort=overall,date&_order=desc,desc&program=IELTS`
  );
  return res;
};

const reducer = (prevState, nextState) => ({ ...prevState, ...nextState });

const ITEM_PER_PAGE_DESKTOP = 6;
const ITEM_PER_PAGE_TABLET = 4;
const ITEM_PER_PAGE_MOBILE = 3;

const HallOfFame = () => {
  const data = useStaticQuery(graphql`
    query {
      hofConnection: allGoogleSpreadsheetDolLandingPageResultIelts(
        filter: { bangVangListed: { eq: "TRUE" } }
        sort: { order: DESC, fields: overall }
      ) {
        distinct(field: bangVangAvatar)
      }
    }
  `);

  const { isDesktop, isTablet } = useDeviceDetect3();
  let limitReview;
  if (isDesktop) {
    limitReview = ITEM_PER_PAGE_DESKTOP;
  } else if (isTablet) {
    limitReview = ITEM_PER_PAGE_TABLET;
  } else {
    limitReview = ITEM_PER_PAGE_MOBILE;
  }

  const { hofConnection } = data;

  const [state, setState] = useReducer(reducer, {
    total: hofConnection.distinct.length,
    loaded: 0,
    hofList: [],
    loading: false,
    size: limitReview
  });

  const { loaded, hofList, size, page = 1 } = state;

  const loadHallOfFame = useCallback(() => {
    setState({ loading: true });
    fetchHallOfFame({ page, limit: size })
      .then(res => {
        const list = res.data;
        setState({
          hofList: hofList.concat(list),
          loaded: loaded + list.length,
          page: page + 1,
          loading: false
        });
      })
      .catch(() => {
        setState({ loading: false });
      });
  }, [hofList, loaded, size, page]);

  useEffect(() => {
    loadHallOfFame();
    // Reason: Fetch once on mount
    /*eslint-disable-next-line*/
  }, []);

  return (
    <HOFContainer>
      <HeadlineIcon
        className="section-title"
        iconHandle="x2dlqr50RdOvP6ZLm83K"
        url="hall-of-fame"
        isHomePage
      >
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          Bảng vàng thành tích của DOL
        </Typography>
      </HeadlineIcon>
      <ListItem hallOfFameList={hofList} />
      <Link to="/hall-of-fame">
        <Button outline className="cta-button">
          <Typography
            style={{ color: colorsV2.primary100 }}
            variant="semi-bold/14-24"
          >
            Xem tất cả
          </Typography>
        </Button>
      </Link>
    </HOFContainer>
  );
};

export const HOFContainer = styled.div`
  display: grid;
  grid-row-gap: 24px;
  > a {
    width: 100%;
    text-align: center;
    .cta-button {
      width: fit-content;
      background: transparent;
      border-radius: 8px;
    }
  }
  .section-title {
    > div {
      display: grid;
      grid-template-columns: 1fr auto;
      justify-content: space-between;
    }
  }
  .headline-icon {
    display: block;
  }
  ${fromScreen(776)} {
    > a {
      display: none;
    }
    grid-row-gap: 40px;
  }

  h3,
  p {
    margin: 0;
  }

  .load-more {
    display: flex;
    justify-content: center;
  }
`;

HallOfFame.propTypes = {
  hofList: PropTypes.arrayOf(PropTypes.shape({})),
  bellowHeader: PropTypes.node,
  paging: PropTypes.boolean,
  order: PropTypes.string
};

HallOfFame.defaultProps = {
  hofList: [],
  bellowHeader: null,
  paging: true
};

export default withHomePageSection(
  HallOfFame,
  {
    style: {
      background: colorsV2.white100
    }
  },
  {}
);
