import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import cl from 'classnames';
import { IconsFeatureShinestar3 } from 'components/Icon/SVGIcons';

const Main = styled.div`
  position: relative;
  background: transparent;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 60px;
  cursor: pointer;

  &.primary {
    border: 1px solid ${colorsV2.primary20};
  }
  &.green {
    border: 1px solid ${colorsV2.green20};
  }
  &.yellow {
    border: 1px solid ${colorsV2.yellow40};
  }
  &.purple {
    border: 1px solid ${colorsV2.purple20};
  }
  &.blue {
    border: 1px solid ${colorsV2.blue20};
  }

  &:hover {
    box-shadow: 0px 12px 24px 0px rgba(48, 66, 120, 0.08),
      0px 3px 6px 0px rgba(48, 66, 120, 0.06),
      0px 0px 1px 0px rgba(48, 66, 120, 0.06);

    &.primary {
      background: ${colorsV2.primary5};
      border: 1px solid ${colorsV2.primary100};
    }
    .icon-primary {
      background: ${colorsV2.primary20};
    }

    &.green {
      background: ${colorsV2.green5};
      border: 1px solid ${colorsV2.green100};
    }
    .icon-green {
      background: ${colorsV2.green20};
    }

    &.yellow {
      background: ${colorsV2.yellow5};
      border: 1px solid ${colorsV2.yellow100};
    }
    .icon-yellow {
      background: ${colorsV2.yellow20};
    }

    &.purple {
      background: ${colorsV2.purple5};
      border: 1px solid ${colorsV2.purple100};
    }
    .icon-purple {
      background: ${colorsV2.purple20};
    }

    &.blue {
      background: ${colorsV2.blue5};
      border: 1px solid ${colorsV2.blue100};
    }
    .icon-blue {
      background: ${colorsV2.blue20};
    }
  }
`;

const Icon = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  svg {
    width: 24px;
    height: 24px;
  }
  &.icon-primary {
    background: ${colorsV2.primary10};
  }
  &.icon-green {
    background: ${colorsV2.green10};
  }
  &.icon-yellow {
    background: ${colorsV2.yellow10};
  }
  &.icon-purple {
    background: ${colorsV2.purple10};
  }
  &.icon-blue {
    background: ${colorsV2.blue10};
  }
`;

const NewTag = styled.div`
  position: absolute;
  display: flex;
  padding: 4px 6px;
  background: ${colorsV2.primary100};
  border-radius: 20px;
  top: -13px;
  right: 28px;
`;

const CourseButtonV3 = ({
  text,
  color = 'primary',
  isNew = false,
  icon = <></>,
  ...rest
}) => {
  return (
    <Main className={color} {...rest}>
      <Icon className={cl(`icon-${color}`)}>{icon}</Icon>
      <Typography variant="semi-bold/20-28">{text}</Typography>
      {isNew && (
        <NewTag>
          <Typography variant="semi-bold/12-16" color={colorsV2.white100}>
            New
          </Typography>
          <IconsFeatureShinestar3 size={16} color={colorsV2.white100} />
        </NewTag>
      )}
    </Main>
  );
};

CourseButtonV3.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string
};

CourseButtonV3.defaultProps = {};

export default React.memo(CourseButtonV3);
