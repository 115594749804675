import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';
import { Typography } from '../../components/DesignSystemV2/Typography';
import { graphql, useStaticQuery } from 'gatsby';
import { HeadlineIcon } from 'components/HeadlineIcon';
import { withHomePageSliderSection } from 'components/withHomePageSliderSection';
import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';
import Marquee from 'react-fast-marquee';

const NewsAboutDolWrapper = styled.div`
  .section-title {
    margin-bottom: 48px;
    display: grid;
    justify-content: center;
    > div {
      display: grid;
      grid-template-columns: 1fr auto;
      justify-content: space-between;
    }
  }
  > a {
    display: none;
  }
  .headline-icon {
    display: block;
  }
`;

const SlideContainer = styled.div`
  .news-image {
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid transparent;
    background: transparent;
    &:hover {
      border: 1px solid rgb(209, 66, 66);
      background: rgb(250, 236, 236);
    }
    img {
      height: 44px;
    }
  }
`;

const NewsLogoSlideItem = styled.a`
  margin: 0 8px;
  ${fromScreen(458)} {
    margin: 0 12px;
  }
`;

const SlideItems = ({ news }) => {
  return (
    <Marquee speed={100} pauseOnHover={true}>
      <div className="keen-slider">
        {news?.map((eachNews, idx) => {
          return (
            <NewsLogoSlideItem
              key={idx}
              className={cl('keen-slider__slide', 'news-image')}
              rel="noopener noreferrer nofollow"
              target="_blank"
              href={eachNews?.actionUrl}
            >
              <LazyImage
                className="image-wrap"
                handle={eachNews?.logoHandle}
                alt={eachNews?.title}
              />
            </NewsLogoSlideItem>
          );
        })}
      </div>
    </Marquee>
  );
};

export const NewsAboutDolV3 = ({ news = [] }) => {
  return (
    <NewsAboutDolWrapper>
      <HeadlineIcon
        className="section-title"
        iconHandle="RY1uavGWQqW2aZ0GGJDo"
        isHomePage
      >
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          Báo chí viết về DOL
        </Typography>
      </HeadlineIcon>
      <SlideContainer>
        <SlideItems news={news} />
      </SlideContainer>
    </NewsAboutDolWrapper>
  );
};

export const GCMSNewsAboutDolV3 = withHomePageSliderSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "LandingCourse_NewsAboutDol_V3" }) {
            content
          }
        }
      }
    `);

    return <NewsAboutDolV3 news={data.gcms.jsonContanier.content} />;
  },
  {},
  {}
);
