import React from 'react';

import { withHomePageSliderSection } from 'components/withHomePageSliderSection';
import { colorsV2 } from 'style/colors-v2';
import styled, { keyframes } from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';
import cl from 'classnames';
import { HeadlineIcon } from 'components/HeadlineIcon';
import ButtonText from 'components/ButtonText';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Marquee from 'react-fast-marquee';

const Main = styled.div`
  .section-title {
    padding: 0 16px;
    margin: 0 auto 24px;
    > div {
      display: grid;
      grid-template-columns: 1fr auto;
      justify-content: space-between;
    }
  }
  .headline-icon {
    display: block;
  }
  ${fromScreen(458)} {
    .section-title {
      max-width: 457px;
    }
  }

  ${fromScreen(776)} {
    .section-title {
      max-width: 712px;
      padding: 0;
      margin: 0 auto 40px;
    }
  }
  ${fromScreen(1144)} {
    .section-title {
      max-width: 100%;
    }
  }
`;

const SlideContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  .keen-slider {
    height: 220px;
  }
  .location-image {
    position: relative;
    border-radius: 16px;
    width: 320px;
  }
  ${fromScreen(776)} {
    gap: 24px;
  }
`;

const Text = styled.div`
  height: 188px;
  width: 100%;
  position: absolute;
  display: grid;
  align-items: end;
  background: linear-gradient(
    180deg,
    rgba(33, 38, 45, 0) 19.31%,
    rgba(33, 38, 45, 0.8) 83.86%,
    #21262d 100%
  );
  padding: 20px;
  bottom: 0;
`;

const FadeIn = keyframes`
  0% {
    bottom: -63px;
  }
  100% {
    bottom: 0;
  }
`;

const LocationSlideItem = styled.div`
  margin: 0 8px;
  .address-content {
    display: none;
  }
  &:hover {
    ${Text} {
      bottom: 0;
      animation: ${FadeIn} 0.5s;
    }
    .address-title {
      margin-bottom: 0;
    }
    .address-content {
      display: block;
    }
  }
  ${fromScreen(458)} {
    margin: 0 12px;
  }
`;

const MaskLeft = styled.div`
  position: absolute;
  display: none;
  height: 100%;
  width: 160px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  z-index: 1;
  pointer-events: none;
  ${fromScreen(776)} {
    display: block;
  }
`;

const MaskRight = styled.div`
  position: absolute;
  display: none;
  height: 100%;
  width: 160px;
  right: -2px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  transform: rotate(180deg);
  z-index: 1;
  pointer-events: none;
  ${fromScreen(776)} {
    display: block;
  }
`;

const TextLinkWrap = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  margin-top: 70px;
  ${fromScreen(776)} {
    display: none;
  }
`;

const SlideItems = ({ locations, rtl = false }) => {
  return (
    <Marquee speed={100} direction={rtl ? 'left' : 'right'} pauseOnHover={true}>
      <div className="keen-slider">
        {locations.map((eachLocation, idx) => {
          return (
            <LocationSlideItem
              key={idx}
              className={cl('keen-slider__slide', 'location-image')}
            >
              <LazyImage
                className="image-wrap"
                src={eachLocation?.image || ''}
                alt="location-image"
              />
              <Text>
                <div>
                  <Typography
                    className="address-title"
                    variant="semi-bold/16-24"
                    tabletVariant="semi-bold/18-24"
                    color={colorsV2.white100}
                    v3
                  >
                    {eachLocation?.shortTitle}
                  </Typography>
                  <Typography
                    className="address-content"
                    variant="regular/14-20"
                    color={colorsV2.white80}
                  >
                    {eachLocation?.location}
                  </Typography>
                </div>
              </Text>
            </LocationSlideItem>
          );
        })}
      </div>
    </Marquee>
  );
};

const AllBrandLocationHome = ({ data }) => {
  const { locations } = data || {};
  const firstLocations = locations?.slice(0, 6) || [];
  const secondLocations = locations?.slice(6) || [];
  return (
    <Main>
      <HeadlineIcon
        iconHandle="0GYERmATmSRJWjiRPE4w"
        url="/he-thong-co-so"
        className="section-title"
        isHomePage
      >
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          Hệ thống cơ sở trên toàn quốc
        </Typography>
      </HeadlineIcon>
      <SlideContainer>
        <SlideItems locations={firstLocations} />
        <SlideItems locations={secondLocations} rtl />
        <MaskLeft />
        <MaskRight />
      </SlideContainer>
      <TextLinkWrap>
        <Link to="/he-thong-co-so">
          <ButtonText content="Xem tất cả" type="primary" />
        </Link>
      </TextLinkWrap>
    </Main>
  );
};

export default withHomePageSliderSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "Branch_Location_V3" }) {
            content
          }
        }
      }
    `);

    return <AllBrandLocationHome data={data.gcms.jsonContanier.content} />;
  },
  {
    style: {
      background: colorsV2.white100
    }
  },
  {}
);
